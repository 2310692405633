import { Component, OnInit, inject } from '@angular/core';
import { Subject, Subscription, distinctUntilChanged, filter, switchMap, tap } from 'rxjs';
import { ServiceWorkerUpdateService } from '@origin8-web/core-ui/service-worker';
import { BrowserExtApiService } from '@origin8-web/core-ui/browser-ext-api';
import { UserService } from '@origin8-web/core-ui/login/providers';
import { UserStore } from '../core/store/user/user.store';
import { NavbarComponent } from './navbar/navbar.component';
import { RouterOutlet } from '@angular/router';
import { ImpersonationWidgetComponent } from '@origin8-web/core-ui/login/components';

@Component({
  selector: 'arc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [NavbarComponent, RouterOutlet, ImpersonationWidgetComponent],
})
export class AppComponent implements OnInit {
  title = 'arc-client';
  unsubscribe$ = new Subject<void>();
  auth$!: Subscription;
  authNotification$!: Subscription;
  userStore = inject(UserStore);

  constructor(
    private serviceWorkerUpdateService: ServiceWorkerUpdateService,
    private browserExtApiService: BrowserExtApiService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.userService
      .getIsLoggedIn$()
      .pipe(
        filter((loggedIn) => !!loggedIn),
        switchMap(() => this.userService.getSelfOrImpersonatedUser$()),
        distinctUntilChanged(),
        tap((userInfo) => {
          this.userStore.setUserInfo(userInfo);
        }),
      )
      .subscribe();

    this.serviceWorkerUpdateService.initCheckForVersionUpdate$().subscribe();
    this.browserExtApiService.checkExtensionIsInstalledAndDisplayErrorMessageIfNot().subscribe();
  }
}
