import { Inject, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { EMPTY, from, merge, Observable, switchMap, tap, timer } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SnackMessageService } from '../snack-message';
import { WINDOW } from '../../shared-providers';

@Injectable({
  providedIn: 'root',
})
export class ServiceWorkerUpdateService {
  constructor(
    private swUpdate: SwUpdate,
    private snackMessageService: SnackMessageService,
    @Inject(WINDOW) private window: Window,
  ) {}

  initCheckForVersionUpdate$(): Observable<void> {
    if (!this.swUpdate.isEnabled) {
      return EMPTY;
    }
    const FIVE_MIN = 300000;
    const THIRTY_FIVE_SEC = 35000;

    const regularCheck$ = timer(THIRTY_FIVE_SEC, FIVE_MIN).pipe(
      switchMap(() => {
        return from(this.swUpdate.checkForUpdate());
      }),
      map(() => undefined),
    );

    const readyVersion$ = this.swUpdate.versionUpdates.pipe(
      filter((ve) => ve.type === 'VERSION_READY'),
      switchMap(() => {
        return this.snackMessageService
          .showWithAction('This app has new updates and needs to refresh. Reload the page now?', 'Reload')
          .onAction();
      }),
      tap(() => {
        this.window.location.reload();
      }),
    );
    return merge(readyVersion$, regularCheck$);
  }
}
